/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { createContext } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { isFormSubmitted } from './form.helper';

import Button from '../components/shared-components/button/Button.js';
import SignInGate from '../components/shared-components/gate/SignInGate.component';

import styleSheet from './scss/_form.module.scss';
import styleSheetPoll from '../components/Polling/poll.module.scss';

import {
    getFromFieldsToViewFormat,
    getFromFieldsToAPIFormat,
    CustomInput
} from './form/helper.js';
import { getAuthHeader, checkForSignIn } from '../helpers/authentication';
import { createProfile } from './apiRequests.js';
import { saveFormSubmitted } from './form.helper.js';
import TickIcon from '../components/shared-components/icons/TickIcon.js';
import ErrorIcon from '../components/shared-components/icons/ErrorIcon';
import { useTranslation } from 'react-i18next';
import HeaderHeroAsset from '../components/Polling/pollHero.component.js';
import FooterSponsorAsset from '../components/Polling/pollSponsor.component.js';

export const FormContext = createContext({});

/**
 * Component for showing a form
 *
 * @param {object} props form props
 * @param {object} props.formData -
 * @param {Function} props.submittedCallback - callback for submit button
 * @param {Function} props.pollId - Parent poll ID when form is linked into a poll
 * @param {Function} props.predictionId - Parent predictionId ID when form is linked into a predictionId
 * @param {object} props.container Form embed container
 * @param {object} props.settingsData Settings data configuration
 * @param {object} props.colourOverrides Colour object configuration
 * @returns {Element} <Form />
 */
function Form({
    formData,
    submittedCallback,
    pollId,
    predictionId,
    container,
    settingsData,
    colourOverrides
}) {
    const [isSignedIn, setIsSignedIn] = useState(false);

    const ssoTokenType = settingsData?.token_name;
    const isGated = formData?.gated;
    const authHeader = getAuthHeader(ssoTokenType);

    useEffect(() => {
        if (container) {
            applyFormSettings();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setIsSignedIn(checkForSignIn(ssoTokenType));
    }, []);

    const applyFormSettings = () => {
        const pollSettingsAttr =
            container.previousElementSibling?.getAttribute('data-fek-settings');
        if (pollSettingsAttr) {
            const settings = JSON.parse(pollSettingsAttr);
            if (settings.primaryColour) {
                container.style.setProperty(
                    '--fek-primary',
                    settings.primaryColour
                );
            }

            if (settings.primaryColourRgb) {
                container.style.setProperty(
                    '--fek-primary-rgb',
                    settings.primaryColourRgb
                );
            }
        } else if (colourOverrides?.hex && colourOverrides?.rgb) {
            container?.style?.setProperty('--fek-primary', colourOverrides.hex);

            container?.style?.setProperty(
                '--fek-primary-rgb',
                colourOverrides.rgb
            );
        }
    };

    const styles = styleSheet.locals || {};
    const { t } = useTranslation();

    // Creates the defaultValues object with null values to enable the isValid prop. E.g. {0: null, 1: null}
    const formFieldsDefaultValues = Object.assign(
        {},
        formData.fields.map(() => null)
    );

    const {
        handleSubmit,
        control,
        formState: { isValid }
    } = useForm({
        defaultValues: formFieldsDefaultValues
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    // This ensures that on liveblog pages the form will show the submission message
    // on live blog entries if the page is refreshed - AND it's not attached to a poll or a prediction
    if (isFormSubmitted(formData?.form_id) && !pollId && !predictionId) {
        setIsSubmitted(true);
    }

    if (
        !formData ||
        formData.status === 'draft' ||
        formData.status === 'closed'
    ) {
        return null;
    }

    const onSubmit = (data) => {
        const fields = getFromFieldsToAPIFormat(data, formData);
        const payload = {
            form_id: formData.form_id,
            fields
        };

        // eslint-disable-next-line no-unused-vars
        createProfile(payload, isGated, authHeader)
            .then(() => {
                setIsSubmitted(true);
                saveFormSubmitted(formData.form_id);
                submittedCallback?.();
                submitError && setSubmitError(false);
            })
            .catch((error) => {
                setSubmitError(true);
                console.error(error.detail ?? error.message);
            });
    };

    const formInputs = getFromFieldsToViewFormat(formData.fields);

    return (
        <>
            <style>
                {styleSheet.toString()}
                {styleSheetPoll.toString()}
            </style>
            <div className={styles.form}>
                {formData.hero_image_url && !pollId && (
                    <HeaderHeroAsset poll={formData} />
                )}
                <div className={styles.formCore}>
                    <h2 className={styles.formTitle}>
                        {formData.intro_title ?? formData.name}
                    </h2>
                    <p className={styles.formDescription}>
                        {formData?.intro_text && (
                            <>
                                {formData?.intro_text}
                                <br />
                            </>
                        )}
                        * {t('dataCapture.requiredField')}
                    </p>

                    <div
                        className={
                            isGated
                                ? styles.formContentGated
                                : styles.formContent
                        }
                    >
                        {isGated && !isSignedIn && <SignInGate type="form" />}
                        {isSubmitted ? (
                            <p className={styles.formConfirmation}>
                                {' '}
                                <TickIcon fill="blue" />{' '}
                                {formData?.completion_text ??
                                    t('dataCapture.confirmationMessage')}
                            </p>
                        ) : (
                            <div className={styles.formBody}>
                                {Object.keys(formInputs).map((input) => (
                                    <Controller
                                        key={input}
                                        name={input}
                                        control={control}
                                        rules={formInputs[input].rules}
                                        defaultValue={
                                            formInputs[input].defaultValue ?? ''
                                        }
                                        render={({ field }) => (
                                            <CustomInput
                                                id={input}
                                                onChange={field.onChange}
                                                {...formInputs[input]}
                                                {...field}
                                            />
                                        )}
                                    />
                                ))}
                                {submitError && (
                                    <span className={styles.formError}>
                                        <ErrorIcon fill="#b63012" />{' '}
                                        {t('dataCapture.sendingErrorMessage')}
                                    </span>
                                )}

                                <div className={styles.formButtoncontainer}>
                                    <Button
                                        text={t('dataCapture.sendButton')}
                                        theme="primary"
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={!isValid}
                                    />
                                </div>
                            </div>
                        )}
                        {formData.sponsor_image_url && !pollId && (
                            <div className={styles.formFooterAsset}>
                                <FooterSponsorAsset
                                    poll={formData}
                                    additionalClass={styles.formFooterAsset}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

Form.propTypes = {
    container: PropTypes.node.isRequired
};

export default Form;
