import { getClientHost } from '../helpers/client';

/**
 * GET /v1/fan/settings
 * Fetches Playbook settings data from Fan API
 *
 * @returns {Promise} -
 */
export const getSsoConfig = () => {
    const SETTINGS_HOST = `${getClientHost()}${process.env.API_PATH_SETTINGS}`;

    const endpoint = `https://${SETTINGS_HOST}/settings`;

    return new Promise((resolve, reject) => {
        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    console.warn('Playbook - Settings data unsuccessful');
                    return null;
                }

                return response.json();
            })
            .then((settingsData) => {
                resolve(settingsData);
            })
            .catch((error) => {
                console.error('Playbook - Settings data unsuccessful', error);
                reject(error);
            });
    });
};

/**
 * Retrieves the SSO (Single Sign-On) token based on the specified type.
 *
 * @param {string} type - The type of SSO provider. Supported types are 'keycloak' and 'okta'.
 * @returns {string|boolean} - Returns the token string for the specified type if recognized:
 *                             - 'kc_token' for 'keycloak'
 *                             - 'okta-token-storage' for 'okta'
 *                             Returns `false` if the type is not recognized.
 */
export const getSsoToken = (type) => {
    if (type === 'keycloak') {
        return 'kc_token';
    } else if (type === 'okta') {
        return 'okta-token-storage';
    }

    return false;
};

/**
 * Retrieves the authorization header based on the specified SSO (Single Sign-On) token type.
 *
 * @param {string} ssoTokenType - The type of SSO provider. Supported types are 'keycloak' and 'okta'.
 * @returns {string|boolean} - Returns the authorization header in the format `Bearer <token>`
 *                             if a valid token is found. Returns `false` if no valid token is available.
 */
export const getAuthHeader = (ssoTokenType) => {
    // From admin config
    if (getSsoToken(ssoTokenType) === 'okta-token-storage') {
        const oktaValue = localStorage.getItem('okta-token-storage') || null;
        const parsedOktaValue = oktaValue ? JSON.parse(oktaValue) : null;

        const jwt = parsedOktaValue?.idToken?.idToken;

        if (jwt) {
            return `Bearer ${jwt}`;
        }

        return false;
    } else if (localStorage.getItem('kc_token')) {
        const jwt = localStorage.getItem('kc_token');
        return `Bearer ${jwt}`;
    }

    // Fallback to the mapping logic for certain clients if no auth header is found
    const configMap = [
        {
            condition: () => window.location.host.includes('ecb'),
            getter: getEcbTokenFromLocalStorage
        }
    ];

    for (const config of configMap) {
        if (config.condition()) {
            const jwt = config.getter();
            if (jwt) {
                return `Bearer ${jwt}`;
            }
        }
    }

    // Final fallback to localStorage if all else fails - incase a Pulselive ID client has forgotten
    // to configure this properly.
    const fallbackJwt = localStorage.getItem('kc_token') || null;
    return fallbackJwt ? `Bearer ${fallbackJwt}` : false;
};

/**
 * Checks if the user is signed in based on the specified SSO (Single Sign-On) token type.
 *
 * @param {string} ssoTokenType - The type of SSO provider. Supported types are 'keycloak' and 'okta'.
 * @returns {boolean} - Returns `true` if a valid authorization header is found, indicating the user is signed in.
 *                      Returns `false` if no valid authorization header is available.
 */
export const checkForSignIn = (ssoTokenType) => {
    const authHeader = getAuthHeader(ssoTokenType);

    if (authHeader) {
        return true;
    }

    return false;
};

/**
 * Retrieves the `idToken` from the `ecb_user` key in local storage.
 *
 * This function checks if the `ecb_user` key exists in local storage, parses the
 * stored JSON string into an object, and retrieves the `idToken` property if available.
 *
 * @returns {string|null} The `idToken` if found, or `null` if not found or an error occurs.
 */
export function getEcbTokenFromLocalStorage() {
    // Retrieve the ecb_user data from local storage
    const ecbUserData = localStorage.getItem('ecb_user');

    // Check if the data exists
    if (!ecbUserData) {
        console.warn('No data found under the key "ecb_user".');
        return null;
    }

    try {
        // Parse the JSON string to an object
        const userData = JSON.parse(ecbUserData);

        // Check if idToken exists in the parsed object
        if (userData.idToken) {
            return userData.idToken;
        }
        console.warn('idToken not found in the ecb_user object.');
        return null;
    } catch (error) {
        console.error('Error parsing ecb_user data:', error);
        return null;
    }
}
